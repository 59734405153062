import React from "react"
import BestilKurv from "../components/bestilling/bestil-kurv"
import Layout from "../components/layout"

const Kurv = () => {
  return (
    <Layout title="Kurv">
      <BestilKurv />
    </Layout>
  )
}

export default Kurv
